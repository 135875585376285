<script lang="ts" setup></script>

<template>
	<div>
		<slot />
		<Footer />
	</div>
</template>

<style scoped></style>
